<template>
  <land-section
    id="my-messages"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 1200px; padding: 0 16px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          class="hidden-sm-and-down"
        >
          <message-category
            ref="wdMsgCategory"
            @click="onCategory"
          />
        </v-col>
        <v-col
          cols="12"
          md="9"
          sm="12"
        >
          <template
            v-if="msgType === msgTypes.LIKE"
          >
            <message-likes
              :items="msgItems"
              :pagination="pagination"
              @paging="changePagination"
            />
          </template>
          <template
            v-else-if="msgType === msgTypes.COMMENT"
          >
            <message-comments
              :items="msgItems"
              :pagination="pagination"
              @paging="changePagination"
            />
          </template>
          <template
            v-else-if="msgType === msgTypes.ATTENTION"
          >
            <message-attentions
              :items="fanItems"
              :pagination="pagination"
              @reply="onReply"
              @paging="changePageFans"
            />
          </template>
          <template
            v-else
          >
            <message-chat
              ref="wdMsgChat"
              :to="toUser"
            />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  import userObj from '@/api/co.user'
  import web from '@/api/web/co.web'

  import mixMessages from '@/pages/mixins/my/mix.my.messages'
  const MsgTypes = web.comm.MessageTypes

  export default {
    metaInfo: { title: '消息中心' },
    components: {
      MessageCategory: () => import('@/pages/sections/my/MessageCategory.vue'),
      MessageChat: () => import('@/pages/sections/my/MessageChat.vue'),
      MessageLikes: () => import('@/pages/sections/my/MessageLikes.vue'),
      MessageComments: () => import('@/pages/sections/my/MessageComments.vue'),
      MessageAttentions: () => import('@/pages/sections/my/MessageAttentions.vue'),
    },
    mixins: [
      mixMessages
    ],
    data () {
      return {
        msgTypes: MsgTypes,
        msgType: MsgTypes.LIKE,
        fromUser: {},
        toUser: {}
      }
    },
    created () {
      this.fromUser = userObj.info.get()
      this.configMessages({})
      this.loadMessages()

      const userId = this.fromUser.userId
      this.configFans({ userId })
    },
    methods: {
      onCategory (ev) {
        // console.log(ev)
        const item = ev.item
        this.msgType = item.type
        if (this.msgType === MsgTypes.CHAT) {
          this.toUser = Object.assign({}, item)
        } else if (this.msgType === MsgTypes.ATTENTION) {
          this.getFans()
        } else {
          this.changeType({
            type: this.msgType
          })
        }
      },
      onReply (ev) {
        const wdCate = this.$refs.wdMsgCategory
        if (wdCate) {
          wdCate.addSection(ev)
          this.msgType = web.comm.MessageTypes.CHAT

          this.toUser = Object.assign({}, ev)
        }
      },
    }
  }
</script>
