
import api from '@/api/co.api'
import web from '@/api/web/co.web'

export default {
  data () {
    return {
      msgParams: null,
      msgItems: [],
      fanParams: null,
      fanItems: [],
      pagination: {
        current: 1,
        length: 0,
        totalVisible: 7
      }
    }
  },
  methods: {
    changeType ({
      chatUserId = 'platform',
      type = web.comm.MessageType.LIKE
    }) {
      const data = this.msgParams.data
      data.chatUserId = chatUserId
      data.type = type
      this.msgParams.reload = true
      api.httpx.getItems(this.msgParams)
    },
    getFans () {
      this.fanParams.reload = true
      api.httpx.getItems(this.fanParams)
    },
    changePagination (number) {
      const params = this.msgParams
      const data = this.msgParams.data
      const index = parseInt(number, 10) || 1

      this.pagination.current = index
      data.currentPage = index
      params.reload = true
      api.httpx.getItems(params)
    },
    changePageFans (number) {
      const params = this.fanParams
      const data = params.data
      const index = parseInt(number, 10) || 1

      this.pagination.current = index
      data.currentPage = index
      params.reload = true
      api.httpx.getItems(params)
    },
    configMessages ({
      chatUserId = 'platform',
      type = web.comm.MessageTypes.LIKE
    }) {
      const me = this
      const executed = function (res) {
        const data = me.msgParams.data
        const src = me.pagination
        const pageSize = parseInt(data.pageSize) || 10
        src.length = Math.ceil(res.total / pageSize)
        me.pagination = Object.assign({}, src)
      }

      this.msgParams = web.imMsg.getParams({
        chatUserId,
        type,
        caches: this.msgItems,
        executed
      })
    },
    loadMessages (reload = true) {
      this.msgParams.reload = reload
      api.httpx.getItems(this.msgParams)
    },
    configFans ({
      userId,
      fansId,
      enabled = 'enabled'
    }) {
      const me = this
      const executed = function (res) {
        const data = me.fanParams.data
        const src = me.pagination
        const pageSize = parseInt(data.pageSize) || 10
        src.length = Math.ceil(res.total / pageSize)
        me.pagination = Object.assign({}, src)
      }

      this.fanParams = web.fans.getParams({
        userId,
        fansId,
        caches: this.fanItems,
        executed
      })
    },
    loadFans (reload = true) {
      this.fanParams.reload = reload
      api.httpx.getItems(this.fanParams)
    }
  }
}
